import React from 'react'
import { Routes, Route } from 'react-router-dom';
import StartMatching from './StartBooking';
import FastMatching from './FastMatching';
import NormalMatching from './NormalMatching';
import FeedBack from './FeedBack';
import SelectPlan from './SelectPlan';
import PaymentSuccess from './PaymentSuccess';
import NotFound from './404';
import Booking from './Booking';

export default function ViewsRoutes() {
  return (
   <Routes>
     <Route path="/" element={<Booking />} />
     <Route path="/plans" element={<SelectPlan />} />
     <Route path="/start-booking" element={<StartMatching />} />
     <Route path="/urgent" element={<FastMatching />} />
     <Route path="/normal" element={<NormalMatching />} />
     <Route path='/feedback' element={<FeedBack/>} />
     <Route path='/cancel' element={<FeedBack/>} />
     <Route path='/payment-success' element={<PaymentSuccess/>} />
     <Route path='/*' element={<NotFound/>} />
   </Routes>
  )
}
