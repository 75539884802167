import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Payment } from "../redux/matchingType.reducer";
import { BASE_URL } from "../utils/const";


export enum IUserInfo {
  NAME = "Name",
  BIRTH_YEAR = "Year of Birth",
  GENDER = "Gender",
  EMAIL_ADDRESS = "Email Address",
  PHONE_NUMBER = "Phone Number",
}

export interface IPatientState {
  name: string;
  lastName: string;
  tel: string;
  email: string;
  gender: string;
  data: any[];
}

export const PatientReducer = createApi({
  reducerPath: "patient-data",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    headers: {
      "Content-Type": "application/json",
    },
    credentials: 'include',
  }),
  endpoints: (builder) => ({
    patientStartsNormalBooking: builder.mutation<any, IPatientState & Payment>({
      query: (patient) => ({
        url: `normal-appointment`,
        method: "POST",
        body: patient,
        // credentials: 'include',
      }),
    }),
    patientStartsFastBooking: builder.mutation<any, { tel: string } & Payment>({
      query: (patient) => ({
        url: `fast-appointment`,
        method: "POST",
        body: patient,
        // credentials: 'include',
      }),
    }),
  }),
});

export const { usePatientStartsNormalBookingMutation, usePatientStartsFastBookingMutation } = PatientReducer;
