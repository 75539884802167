import React, { useEffect, useState } from 'react'
import QuestionsButton from './questionsButton'
import { useDispatch, useSelector } from 'react-redux';
import { emptyChecks, radioBoxCheckedRating } from '../../redux/questions.reducer';
import { RootState } from '../../redux/store';
import RedirectingToStrip from '../payment/StripeRedirection';
import { QuestionFormPaths, QuestionType } from '../../data/questions';

export interface Answer {
 title: string;
 rating: number;
 placeHolder?: string;
 inputType: InputType;
 selection?: string[]
 selectedAnswer?: string;
 selectPath?: QuestionFormPaths;
}

export enum InputType {
    TEXT = 'Text',
    RADIO = 'Radio',
    CHECKBOX = 'Checkbox',
    SELECT = 'SELECT',
    TEXTAREA = 'TextArea',
    DATE = 'Date',
    EMAIL = 'email',
    TEL = 'tel',
    NUMBER = 'number'
}


export interface QuestionsData {
question: string;
answers: Answer[];
image: string;
path: QuestionType;
}

type Props = {
  data: QuestionsData[];
}

type AnswerResult = {question: string} & Answer & {answer: string | string[]};

export default function RowDivider({data}:Props) {
    const [collectedData, setCollectedAnswerData] = useState<any[]>([]);
    const [counter, setCounter] = useState<number>(0);
    const [totalData, setTotalData] = useState<number>(0);
    const [currentData, setCurrentData] = useState<QuestionsData>(data[0] as QuestionsData);
    const [currentAnswer, setCurrentAnswer] = useState<AnswerResult>({} as AnswerResult);
    const [isMatching, setIsMatching] = useState(false);
    const [collectInputsData, setCollectedData] = useState<Object>({});
    const dispatch = useDispatch();
    const answersData: any = useSelector<RootState>(state => state.answers.data)
    const selectedPath: any = useSelector<RootState>(state => state.answers.selectedPaths);



    useEffect(() => {
      setCurrentAnswer({} as AnswerResult);
      dispatch(emptyChecks())
    },[counter, dispatch])

    useEffect(() => {
     const currentDataFilter = data.filter(q=>(
        q.path === QuestionType.START ||
        q.path === QuestionType.SELECTION ||
        q.path === QuestionType.OTHER ||
        q.path === QuestionType.END ||
        selectedPath.includes(q.path)
        ))
     setCurrentData(currentDataFilter[counter]);
     setTotalData(currentDataFilter.length);
    },[counter, data,selectedPath]);

     if(isMatching){
        return <RedirectingToStrip />;
    }

    const onNextQuestion = () => {
      dispatch(radioBoxCheckedRating(0));
        if(counter + 1 !== totalData){
        setCounter(counter+1);
        // setCurrentData(currentDataFilter);
        if(!collectedData.includes(collectInputsData)){
            setCollectedAnswerData([...collectedData, collectInputsData, currentAnswer])
        }else {
            setCollectedAnswerData([...collectedData, currentAnswer])
        }
     }
     else {
      console.log('start booking')
      onStartBooking()
     }
  }

    const onBackQuestion = () => {
        if(counter !==0){
        setCounter(counter-1);
        const currentDataFilter = data[counter-1]
        setCurrentData(currentDataFilter);
       }
    }

    const onCollectData = (value: Answer) => {
      value.selectedAnswer = value.title;
    }

    const onStartBooking = async() => {
       await localStorage.setItem('answers', JSON.stringify(answersData))
       setIsMatching(true);
    }

  return (
    <div className='flex flex-col items-center justify-center w-full h-auto'>
        <span className='text-xl my-1'>{`${(counter+1).toString()}/${totalData}`} </span>
    <div className='flex flex-wrap-reverse
    justify-center items-center
    flex-row gap-1 w-full
    min-h-80 p-2 border-2 rounded shadow-md'>
     <div className='flex flex-col lg:w-3/4 sm:w-full md:w-full  justify-center items-center'>
         <h1 className='h1 lg:text-2xl md:text-2xl sm:text-2xl p-2 text-blue-500 text-center'>{currentData.question}</h1>
      <div className='flex flex-col gap-1 w-full lg:w-3/4 md:w-3/4 sm:w-full'>
      {currentData.answers.map((value)=> <QuestionsButton
          placeHolder={value.placeHolder}
          inputType={value.inputType}
          rating={value.rating}
          title={value.title}
          onClick={()=>onCollectData(value)}
          selection={value.selection}
          question={currentData.question}
          onNext={onNextQuestion}
          path={currentData.path}
          selectPath={value.selectPath}
          collectedData={answersData[counter]}
          />)}
        </div>
     </div>
     {/* <div className='flex grow lg:justify-start justify-center items-center'>
           <img src={currentData.image}
           width={300}
           height={200}
           alt='matching system'
           />
    </div> */}
      </div>

      <div className='flex flex-row gap-2 justify-around w-3/4 p-2'>
        <button
        onClick={onBackQuestion}
        disabled={counter === 0}
        className='button border-2 px-4 text-2sm lg:text-xl md:text-xl rounded w-60 p-1 font-bold'>Back</button>
        <button
        disabled={answersData.length === 0}
        onClick={onNextQuestion}
        className='button border-2 px-2 rounded w-80 bg-blue-500 text-white font-bold text-2sm lg:text-xl md:text-xl disabled:bg-blue-100'>Next or Skip</button>
     
       {
        // counter+1 === currentDataFilterLength ? (
        //  <button
        // disabled={answersData.length === 0}
        // onClick={()=>onStartBooking()}
        // className='button border-2 px-2 rounded w-60 bg-blue-500 text-white font-bold text-2sm lg:text-xl md:text-xl disabled:bg-blue-100'>Start Matching</button>
        // ) :
        //    
      }
      </div>
    </div>
  )
}
