import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { chooseBookingPlan } from '../redux/paymentPlan.reducer';
import BenefitsList from '../component/payment/BenefitsList';
import { RootState } from '../redux/store';
import { PickedAppointmentDay } from '../types/types';

export const PaymentsPlans = [
  { title: 'For single online therapy sessions', price: 90, desc:'Get connected with a therapy, get matched and connected when you are available', type: PickedAppointmentDay.SAME_DAY },
  { title: 'For same day online therapy single session', price: 120, desc:'Get connected with a therapy, get matched and connected when you are available', type: PickedAppointmentDay.SAME_DAY },
  { title: 'Get Started with in just 5 mins', price: 180, desc:'Get started with mental health support in just 5 mins , IcareWellBeing connects you directly through whatsapp, simply send "talk to a therapy in 5 mins"', type: PickedAppointmentDay.FIVE_MINUTES  },
  { title: 'Therapy At Home', price: 350, desc:'Get connected with a therapy, get matched and connected when you are available', type: PickedAppointmentDay.NEXT_DAYS  },
];

export default function SelectPlan() {
  const [selectedType, setSelectedType] = useState<number>(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pickedAppointment = useSelector<RootState>(state=> state.bookingType.pickedAppointment);

  const getStyle = (index: number) => {
    if (selectedType === index) return 'border-sky-500 shadow-2xl';
    return '';
  };

  const onSelect = (type: number) => {
    setSelectedType(type);
  };

  const onStartMatching = (index: number) => {
    dispatch(chooseBookingPlan(index));
    // switch(pickedAppointment){
    //   case PickedAppointmentDay.NEXT_DAYS: 
    //      return;
    //   case PickedAppointmentDay.SAME_DAY:
    //   case PickedAppointmentDay.OTHER:
    //     return;
    // }
    navigate('/start-booking');
  };

  return (
    <div className="flex flex-col justify-center items-center p-4 gap-6">
      <h1 className="text-2xl lg:text-4xl text-center font-serif mb-10">Please choose how many sessions</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 w-full max-w-4xl">
        {PaymentsPlans.filter(p=> p.type === pickedAppointment).map((plan, index) => (
          <div key={plan.price} className={`p-4 border-2 rounded-lg mb-10 ${getStyle(index)} cursor-pointer hover:shadow-2xl duration-500`} onClick={() => onSelect(index)}>
            <div className="bg-blue-400 py-4 px-6 rounded-t-lg mt-[-60px] h-[150px] lg:h-[100px]">
              <h2 className="text-2xl text-center font-bold text-white">{plan.title}</h2>
              {/* <p className="text-4xl text-center font-bold text-white mt-2">AED{plan.price}</p> */}
            </div>
            <div className="p-6 bg-white rounded-b-lg">
              <p className="text-center text-gray-700">{plan.desc}</p>
              <BenefitsList/>
              <button onClick={() => onStartMatching(index)} className="mt-4 w-full py-3 bg-blue-500 text-white rounded-lg font-bold text-lg hover:bg-sky-500 duration-700">
                Pay {plan.price}.00 AED
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
