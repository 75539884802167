import React, { useState } from 'react'
import Appointments from '../component/appointments/Appointments'

export default function Booking() {
    const [tel, setTel] = useState('')
    const onTelChange = async(value: string) => {
        // let phone = '(+97)';
        // phone = phone + value;
        await localStorage.setItem('userTel', value)
        setTel(value);
    }

  return (
    <div className='flex flex-col justify-center items-center gap-4 h-[50vh] w-full bg-gray-100 p-2 lg:p-4 rounded'>
       <h2 className='text-2sm md:text-xl lg:text-2xl font-serif text-center'>Fast and quick matching, share your phone number and we will take care of the rest</h2>
       <input
       className='w-full lg:w-2/4 md:w-2/4 border-2 p-4 rounded border-sky-500 number'
       placeholder='ex. +(97) 123456789 .min 10'
       type='number'
       pattern="\+?\d{10,}"
       required
       value={tel}
       onChange={(e)=>onTelChange(e.target.value)}
       />
        <Appointments disabled={!(tel.length > 9)} tel={tel} />
    </div>
  )
}
