import { InputType, QuestionsData } from "../component/stepper/rowDivider";
import { IUserInfo } from "../hooks/useSendPatient";

export enum QuestionFormPaths {
  Anxiety = "Anxiety",
  Depression = "Depression",
  Eating_Disorders = "Eating Disorders",
  PTSD = "PTSD",
  ADDICTION = "Addiction",
  OCD = "OCD",
  Bipolar_Disorder = "Bipolar Disorder",
  Personality_Disorders = "Personality Disorders",
  Couple_Family_Issues = "Couple/Family Issues",
  Child_and_Adolescent_Issues = "Child and Adolescent Issues",
  OTHER = "Other",
}

export enum QuestionType {
  SELECTION = 'Selection',
  START = "Start",
  END= 'END',
  OTHER = "Other",
  Anxiety = "Anxiety",
  Depression = "Depression",
  Eating_Disorders = "Eating Disorders",
  PTSD = "PTSD",
  ADDICTION = "Addiction",
  OCD = "OCD",
  Bipolar_Disorder = "Bipolar Disorder",
  Personality_Disorders = "Personality Disorders",
  Couple_Family_Issues = "Couple/Family Issues",
  Child_and_Adolescent_Issues = "Child and Adolescent Issues",
}

export const questionsData: QuestionsData[] = [
  {
    question:
      "Primary reason for seeking therapy (Please select the main reason you are seeking therapy)",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Understanding my issues",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "Working on past issues",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "Working on present issues",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "Changing habits or behaviors",
        rating: 4,
        inputType: InputType.RADIO,
      },
      {
        title: "Other (please specify)",
        rating: 5,
        inputType: InputType.TEXT,
      },
    ],
    path: QuestionType.START,
  },
  {
    question: "Do you have any medical conditions we should be aware of?",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "No",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "Yes",
        rating: 2,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.START,
  },
  {
    question: "Have you received mental health treatment before? (Yes/No)",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "No",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "Yes",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "If yes, please provide details",
        rating: 3,
        inputType: InputType.TEXT,
      },
    ],
    path: QuestionType.START,
  },
  {
    question:
      "Please select any symptoms or challenges you are currently experiencing. Check all that apply",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Anxiety",
        rating: 1,
        inputType: InputType.CHECKBOX,
        selectPath: QuestionFormPaths.Anxiety,
      },
      {
        title: "Depression",
        rating: 2,
        inputType: InputType.CHECKBOX,
        selectPath: QuestionFormPaths.Depression,
      },
      {
        title: "Eating Disorders",
        rating: 3,
        inputType: InputType.CHECKBOX,
        selectPath: QuestionFormPaths.Eating_Disorders,
      },
      {
        title: "PTSD",
        rating: 4,
        inputType: InputType.CHECKBOX,
        selectPath: QuestionFormPaths.PTSD,
      },
      {
        title: "Addiction",
        rating: 5,
        inputType: InputType.CHECKBOX,
        selectPath: QuestionFormPaths.ADDICTION,
      },
      {
        title: "OCD",
        rating: 6,
        inputType: InputType.CHECKBOX,
        selectPath: QuestionFormPaths.OCD,
      },
      {
        title: "Bipolar Disorder",
        rating: 7,
        inputType: InputType.CHECKBOX,
        selectPath: QuestionFormPaths.Bipolar_Disorder,
      },
      {
        title: "Personality Disorders",
        rating: 8,
        inputType: InputType.CHECKBOX,
        selectPath: QuestionFormPaths.Personality_Disorders,
      },
      {
        title: "Couple/Family Issues",
        rating: 9,
        inputType: InputType.CHECKBOX,
        selectPath: QuestionFormPaths.Couple_Family_Issues,
      },
      {
        title: "Child and Adolescent Issues",
        rating: 10,
        inputType: InputType.CHECKBOX,
        selectPath: QuestionFormPaths.Child_and_Adolescent_Issues,
      },
      {
        title: "Other (please specify):",
        rating: 11,
        inputType: InputType.TEXT,
        selectPath: QuestionFormPaths.OTHER,
      },
    ],
    path: QuestionType.SELECTION,
  },
  {
    question: "Please select your main goals for therapy. Check all that apply",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Understand my issues better",
        rating: 1,
        inputType: InputType.CHECKBOX,
      },
      {
        title: "Resolve past issues",
        rating: 2,
        inputType: InputType.CHECKBOX,
      },
      {
        title: "Address current issues",
        rating: 3,
        inputType: InputType.CHECKBOX,
      },
      {
        title: "Change specific habits or behaviors",
        rating: 4,
        inputType: InputType.CHECKBOX,
      },
      {
        title: "Improve relationships",
        rating: 5,
        inputType: InputType.CHECKBOX,
      },
      {
        title: "Develop coping skills",
        rating: 6,
        inputType: InputType.CHECKBOX,
      },
      {
        title: "Increase self-esteem/self-confidence",
        rating: 7,
        inputType: InputType.CHECKBOX,
      },
      {
        title: "Manage stress or anxiety",
        rating: 8,
        inputType: InputType.CHECKBOX,
      },
      {
        title: "Manage depression",
        rating: 9,
        inputType: InputType.CHECKBOX,
      },
      {
        title: "Improve communication skills",
        rating: 10,
        inputType: InputType.CHECKBOX,
      },
      {
        title: "Other (please specify)",
        rating: 11,
        inputType: InputType.TEXT,
      },
    ],
    path: QuestionType.Couple_Family_Issues,
  },
  {
    question: "Do you have any preferences for the gender of your therapist?",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Male",
        rating: -1,
        inputType: InputType.RADIO,
      },
      {
        title: "Female",
        rating: -2,
        inputType: InputType.RADIO,
      },
      {
        title: "No Preference",
        rating: -3,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.OTHER,
  },
  {
    question: "Are you interested in individual, couples, or family therapy?",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Individual",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "Couples",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "Family",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "Not Sure",
        rating: 4,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.OTHER,
  },
  {
    question:
      "Please select any therapeutic approaches you are interested in. Check all that apply",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Directive: Providing structured guidance and tasks",
        rating: 1,
        inputType: InputType.CHECKBOX,
      },
      {
        title: "Non-Directive: Allowing you to guide the conversation",
        rating: 2,
        inputType: InputType.CHECKBOX,
      },
      {
        title: "Task-Oriented: Assigning task or homework",
        rating: 3,
        inputType: InputType.CHECKBOX,
      },
      {
        title:
          "Insight-Oriented: Focusing on gaining insight into thoughts and behaviors",
        rating: 4,
        inputType: InputType.CHECKBOX,
      },
      {
        title: "Other (please specify)",
        rating: 5,
        inputType: InputType.TEXT,
      },
    ],
    path: QuestionType.OTHER,
  },
  {
    question:
      "Please select any preferred therapy modalities. Check all that apply",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Video Call",
        rating: 1,
        inputType: InputType.CHECKBOX,
      },
      {
        title: "Phone Call",
        rating: 2,
        inputType: InputType.CHECKBOX,
      },
      {
        title: "Messaging",
        rating: 3,
        inputType: InputType.CHECKBOX,
      },
      {
        title: "Other (please specify)",
        rating: 4,
        inputType: InputType.TEXT,
      },
    ],
    path: QuestionType.OTHER,
  },
  {
    question: "Additional Information",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: `
        Is there any other information you believe is important for us to know?
By submitting this form, I confirm that all the information provided is accurate. I understand that the information will be used to match me with a therapist who can best meet my needs.
        `,
        rating: 1,
        inputType: InputType.TEXTAREA,
      },
    ],
    path: QuestionType.OTHER,
  },
  {
    question:
      "In the last few months, how often have you had conflicts with your partner?",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Never",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "Rarely",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "Sometimes",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "Frequently",
        rating: 4,
        inputType: InputType.RADIO,
      },
      {
        title: "Always",
        rating: 5,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.Couple_Family_Issues,
  },
  {
    question: "How would you describe the communication with your partner?",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Very poor",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "Poor",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "Neutral",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "Good",
        rating: 4,
        inputType: InputType.RADIO,
      },
      {
        title: "Very good",
        rating: 5,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.Couple_Family_Issues,
  },
  {
    question:
      "Do you feel that you share similar goals and values with your partner?",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Not at all ",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "To some extent",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "Neutral",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "Quite",
        rating: 4,
        inputType: InputType.RADIO,
      },
      {
        title: "Completely",
        rating: 5,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.Couple_Family_Issues,
  },
  {
    question:
      "How satisfied are you with your relationship with your partner overall?",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Very dissatisfied",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "Dissatisfied",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "Neutral",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "Satisfied",
        rating: 4,
        inputType: InputType.RADIO,
      },
      {
        title: "Very satisfied",
        rating: 5,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.Couple_Family_Issues,
  },
  {
    question: "To what extent do you feel supported by your partner?",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Not supported at all",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "Little supported",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "Neutral",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "Quite supported",
        rating: 4,
        inputType: InputType.RADIO,
      },
      {
        title: "Very supported",
        rating: 5,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.Couple_Family_Issues,
  },
  {
    question: "Are you satisfied with your current job?",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Very dissatisfied",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "Dissatisfied",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "Neutral",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "Satisfied",
        rating: 4,
        inputType: InputType.RADIO,
      },
      {
        title: "Very satisfied",
        rating: 5,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.OTHER,
  },
  {
    question: "Do you experience work-related stress?",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Never",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "Rarely",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "Sometimes",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "Frequently",
        rating: 4,
        inputType: InputType.RADIO,
      },
      {
        title: "Always",
        rating: 5,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.Anxiety,
  },
  {
    question:
      "Do you feel that you have opportunities for professional growth in your job? ",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Not at all",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "To some extent",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "Neutral",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "Quite",
        rating: 4,
        inputType: InputType.RADIO,
      },
      {
        title: "Completely",
        rating: 5,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.Depression,
  },
  {
    question: "How would you describe the work environment in your job?",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Very negative",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "Negative",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "Neutral",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "Positive",
        rating: 4,
        inputType: InputType.RADIO,
      },
      {
        title: "Very positive",
        rating: 5,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.Depression,
  },
  {
    question: "To what extent does work affect your emotional well-being?",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Negatively",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "Somewhat negatively",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "Neutral",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "Somewhat positively",
        rating: 4,
        inputType: InputType.RADIO,
      },
      {
        title: "Positively",
        rating: 5,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.Depression,
  },
  {
    question: "How often do you feel lonely?",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Never",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "Rarely",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "Sometimes",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "Frequently",
        rating: 4,
        inputType: InputType.RADIO,
      },
      {
        title: "Always",
        rating: 5,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.Depression,
  },
  {
    question: "Do you feel you have a strong social support network?",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Not at all",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "To some extent",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "Neutral",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "Quite",
        rating: 4,
        inputType: InputType.RADIO,
      },
      {
        title: "Completely",
        rating: 5,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.Depression,
  },
  {
    question: "How motivated do you feel in your daily life?",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Not motivated at all",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "Little motivated",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "Moderately motivated",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "Quite motivated",
        rating: 4,
        inputType: InputType.RADIO,
      },
      {
        title: "Very motivated",
        rating: 5,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.Anxiety,
  },
  {
    question: "Do you have difficulty finding meaning in your life?",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Never",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "Rarely",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "Sometimes",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "Frequently",
        rating: 4,
        inputType: InputType.RADIO,
      },
      {
        title: "Always",
        rating: 5,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.Depression,
  },
  {
    question: "Do you feel you can trust the people around you?",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Not at all",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "To some extent",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "Neutral",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "Quite",
        rating: 4,
        inputType: InputType.RADIO,
      },
      {
        title: "Completely",
        rating: 5,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.Depression,
  },
  {
    question: "How often do you postpone important tasks?",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Never",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "Rarely",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "Sometimes",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "Frequently",
        rating: 4,
        inputType: InputType.RADIO,
      },
      {
        title: "Always",
        rating: 5,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.Anxiety,
  },
  {
    question: "How organized do you feel in your daily life?",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Very disorganized",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "Disorganized",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "Neutral",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "Organized",
        rating: 4,
        inputType: InputType.RADIO,
      },
      {
        title: "Very organized",
        rating: 5,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.OCD,
  },
  {
    question: "Do you feel you have the ability to reach your goals?",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "I do not have that ability",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "I have little ability",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "Neutral",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "I have quite a bit of ability ",
        rating: 4,
        inputType: InputType.RADIO,
      },
      {
        title: "I have a lot of ability",
        rating: 5,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.Personality_Disorders,
  },
  {
    question: "How difficult do you find it to make important decisions?",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Very difficult",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "Difficult",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "Neutral",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "Easy",
        rating: 4,
        inputType: InputType.RADIO,
      },
      {
        title: "Very easy",
        rating: 5,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.Personality_Disorders,
  },
  {
    question: "How satisfied are you with your level of productivity?",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Very dissatisfied",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "Dissatisfied",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "Neutral",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "Satisfied",
        rating: 4,
        inputType: InputType.RADIO,
      },
      {
        title: "Very satisfied",
        rating: 5,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.Personality_Disorders,
  },
  {
    question: "Do you feel that something important is missing in your life?",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Never",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "Rarely",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "Sometimes",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "Frequently",
        rating: 4,
        inputType: InputType.RADIO,
      },
      {
        title: "Always",
        rating: 5,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.Personality_Disorders,
  },
  {
    question: "How often do you feel overwhelmed by feelings of emptiness?",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Never",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "Rarely",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "Sometimes",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "Frequently",
        rating: 4,
        inputType: InputType.RADIO,
      },
      {
        title: "Always",
        rating: 5,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.Personality_Disorders,
  },
  {
    question:
      "How fulfilling have your past activities or relationships been in providing you with a sense of fulfillment?",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Not fulfilling at all",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "Slightly fulfilling",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "Moderately fulfilling",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "Very fulfilling",
        rating: 4,
        inputType: InputType.RADIO,
      },
      {
        title: "Extremely fulfilling",
        rating: 5,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.Personality_Disorders,
  },
  {
    question: "How satisfied are you with your life in general at this time?",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Very dissatisfied",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "Dissatisfied",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "Neutral",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "Satisfied",
        rating: 4,
        inputType: InputType.RADIO,
      },
      {
        title: "Very satisfied",
        rating: 5,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.Personality_Disorders,
  },
  {
    question:
      "How optimistic are you about finding ways to fill the emptiness feeling?",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Not optimistic at all",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "Slightly optimistic",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "Moderately optimistic",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "Very optimistic",
        rating: 4,
        inputType: InputType.RADIO,
      },
      {
        title: "Extremely optimistic",
        rating: 5,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.Personality_Disorders,
  },
  {
    question: "How often do you feel sad or depressed?",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Never",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "Rarely",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "Sometimes",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "Frequently",
        rating: 4,
        inputType: InputType.RADIO,
      },
      {
        title: "Always",
        rating: 5,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.Depression,
  },
  {
    question:
      "Do you feel you have lost interest in activities you used to enjoy?",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Never",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "Rarely",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "Sometimes",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "Frequently",
        rating: 4,
        inputType: InputType.RADIO,
      },
      {
        title: "Always",
        rating: 5,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.Depression,
  },
  {
    question: "Do you experience changes in your appetite or weight?",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Never",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "Rarely",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "Sometimes",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "Frequently",
        rating: 4,
        inputType: InputType.RADIO,
      },
      {
        title: "Always",
        rating: 5,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.Depression,
  },
  {
    question: "Do you feel fatigued or lack energy frequently?",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Never",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "Rarely",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "Sometimes",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "Frequently",
        rating: 4,
        inputType: InputType.RADIO,
      },
      {
        title: "Always",
        rating: 5,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.Depression,
  },
  {
    question: "Have you had thoughts of death or suicide?",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Never",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "Rarely",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "Sometimes",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "Frequently",
        rating: 4,
        inputType: InputType.RADIO,
      },
      {
        title: "Always",
        rating: 5,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.Depression,
  },
  {
    question: "Do you feel anxiety or nervousness frequently?",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Never",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "Rarely",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "Sometimes",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "Frequently",
        rating: 4,
        inputType: InputType.RADIO,
      },
      {
        title: "Always",
        rating: 5,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.Anxiety,
  },
  {
    question:
      "Do you experience physical symptoms such as palpitations, sweating, or tremors due to anxiety?",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Never",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "Rarely",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "Sometimes",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "Frequently",
        rating: 4,
        inputType: InputType.RADIO,
      },
      {
        title: "Always",
        rating: 5,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.Anxiety,
  },
  {
    question:
      "Do you feel that your anxiety makes it difficult to perform daily tasks?",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Not difficult at all",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "A little difficult",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "Neutral",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "Quite difficult",
        rating: 4,
        inputType: InputType.RADIO,
      },
      {
        title: "Very difficult",
        rating: 5,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.Anxiety,
  },
  {
    question: "Do you have excessive worries or irrational thoughts?",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Never",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "Rarely",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "Sometimes",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "Frequently",
        rating: 4,
        inputType: InputType.RADIO,
      },
      {
        title: "Always",
        rating: 5,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.Anxiety,
  },
  {
    question: "How controllable do you feel your anxiety is?",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Not controllable at all ",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "Slightly controllable",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "Neutral",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "Quite controllable",
        rating: 4,
        inputType: InputType.RADIO,
      },
      {
        title: "Very controllable",
        rating: 5,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.Anxiety,
  },
  {
    question: "Have you experienced a traumatic event in the past?",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "No traumatic events",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "Yes, one traumatic event",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "Yes, a few traumatic events ",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "Yes, several traumatic events",
        rating: 4,
        inputType: InputType.RADIO,
      },
      {
        title: "Yes, many traumatic events",
        rating: 5,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.PTSD,
  },
  {
    question:
      "How often do you experience memories or dreams related to the traumatic event?",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Never",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "Rarely",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "Sometimes",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "Frequently",
        rating: 4,
        inputType: InputType.RADIO,
      },
      {
        title: "Always",
        rating: 5,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.PTSD,
  },
  {
    question:
      "How do you feel in situations that remind you of the traumatic event? ",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "Very bad",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "Bad",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "Neutral",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "Good",
        rating: 4,
        inputType: InputType.RADIO,
      },
      {
        title: "Very good",
        rating: 5,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.PTSD,
  },
  {
    question:
      "Have you noticed changes in your mood or behavior since the last traumatic event?",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "I have not noticed any changes",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "I have noticed a few changes",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "I have noticed several changes",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "I have noticed many changes",
        rating: 4,
        inputType: InputType.RADIO,
      },
      {
        title: "I have noticed a great deal of changes",
        rating: 5,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.PTSD,
  },
  {
    question:
      "Do you feel you need support to deal with the aftermath of the traumatic event?",
    image: "/assets/questionImage.webp",
    answers: [
      {
        title: "I do not need support",
        rating: 1,
        inputType: InputType.RADIO,
      },
      {
        title: "I need little support",
        rating: 2,
        inputType: InputType.RADIO,
      },
      {
        title: "Neutral",
        rating: 3,
        inputType: InputType.RADIO,
      },
      {
        title: "I need quite a bit of support",
        rating: 4,
        inputType: InputType.RADIO,
      },
      {
        title: "I need a lot of support",
        rating: 5,
        inputType: InputType.RADIO,
      },
    ],
    path: QuestionType.PTSD,
  },
  {
    question: "Personal Details",
    answers: [
      {
        title: IUserInfo.NAME,
        rating: -1,
        placeHolder: "full name",
        inputType: InputType.TEXT,
      },
      {
        title: IUserInfo.BIRTH_YEAR,
        rating: -1,
        placeHolder: "ex. 1999",
        inputType: InputType.NUMBER,
      },
      {
        title: IUserInfo.GENDER,
        rating: -1,
        inputType: InputType.SELECT,
        selection: ["Male", "Female", "Other"],
      },
      {
        title: IUserInfo.EMAIL_ADDRESS,
        rating: -1,
        inputType: InputType.EMAIL,
        placeHolder: "ex. example@example.com",
      },
    ],
    image: "/assets/questionImage.webp",
    path: QuestionType.END,
  },
];