import { createSlice } from "@reduxjs/toolkit";

export enum BookingPlan {
  Free,
  Starter,
  Most,
  Premium,
  Other,
}

export interface Booking {
  selectedPlan: BookingPlan;
  sessionId: string;
}

const initialState: Booking = {
  selectedPlan: BookingPlan.Other,
  sessionId: '',
};

export const bookingPlan = createSlice({
  name: "booking-plan",
  initialState,
  reducers: {
    chooseBookingPlan: (state, action) => {
     
      switch (action.payload) {
        case -1:
          state.selectedPlan = BookingPlan.Free;
          break;
        case 0:
          state.selectedPlan = BookingPlan.Starter;
          break;
        case 1:
          state.selectedPlan = BookingPlan.Most;
          break;
        case 2:
          state.selectedPlan = BookingPlan.Premium;
          break;
        default:
          state.selectedPlan = BookingPlan.Other;
      }
    },
    setSessionId:(state,action) => {
        state.sessionId = action.payload;
    }
  },
});

export const { chooseBookingPlan, setSessionId } = bookingPlan.actions;

export default bookingPlan.reducer;
