import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadset, faLock, faMoneyBillTransfer, faClock } from '@fortawesome/free-solid-svg-icons';

const BenefitsList = () => {
  return (
    <div className="mt-4 space-y-2">
      <div className="flex items-center">
        <FontAwesomeIcon icon={faHeadset} className="text-blue-400 mr-2" />
        <span>12/7 support</span>
      </div>
      <div className="flex items-center">
        <FontAwesomeIcon icon={faLock} className="text-blue-400 mr-2" />
        <span>Safe discussion</span>
      </div>
      <div className="flex items-center">
        <FontAwesomeIcon icon={faMoneyBillTransfer} className="text-blue-400 mr-2" />
        <span>Money back guaranteed</span>
      </div>
      <div className="flex items-center">
        <FontAwesomeIcon icon={faClock} className="text-blue-400 mr-2" />
        <span>Choose time suitable for you</span>
      </div>
    </div>
  );
};

export default BenefitsList;
