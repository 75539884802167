import React, { useState } from 'react'
// import Confirmation from '../component/confirmation';
import { useDispatch } from 'react-redux';
import { setUserTel } from '../redux/questions.reducer';
import RedirectingToStrip from '../component/payment/StripeRedirection';

export default function FastMatching() {

    const [isConfirmed, setIsConfirmed] = useState(false);
    const [tel, setTel] = useState('');
    const dispatch = useDispatch();

    const onSubmit = (e: any) => {
       e.preventDefault();
       dispatch(setUserTel(tel))
       setIsConfirmed(true);
    }

    if(isConfirmed){
        return <RedirectingToStrip />
    }

  return (
    <form 
    onSubmit={onSubmit}
    className='flex flex-col gap-4 justify-center items-center'>
        <img
        src='assets/call-booking.png'
        height={200}
        width={200}
        alt=''
        />
    <h2 className='text-2xl font-serif text-center'>Please type your number and we will match you to the best available therapy</h2>
       <input
       className='w-3/4 border-2 p-4 rounded border-sky-500'
       placeholder='ex. 123456789 .min 10'
       type='tel'
       pattern="\+?\d{10,}"
       required
       onChange={(e)=>setTel(e.target.value)}
       />
       <button
       type='submit'
        className='button border-2 px-6 py-2 rounded w-60 p-1 bg-blue-500 text-white font-bold text-xl disabled:bg-blue-100 duration-500'
       >
        Start Matching
       </button>
        <span className='text-sm text-center'>* by clicking start match you are agree to our terms and conditions</span>
    </form>
  )
}
