import React, { MouseEventHandler, useEffect, useState } from 'react';
import { InputType } from './rowDivider';
import { useDispatch, useSelector } from 'react-redux';
import { addData, radioBoxCheckedRating, removeData, removeQuestionPath, setQuestionPath } from '../../redux/questions.reducer';
import { RootState } from '../../redux/store';
import { QuestionFormPaths, QuestionType } from '../../data/questions';

type Props = {
  title: string;
  onClick: (value: any) => void;
  rating: number;
  inputType: InputType;
  selection?: string[];
  question: string;
  placeHolder?: string;
  onNext: () => void;
  path?: QuestionType;
  selectPath?: QuestionFormPaths;
  collectedData: any;
}

export default function QuestionsButton({title, question, rating, inputType,placeHolder, selection, onNext, path, selectPath, collectedData}: Props) {
  const currentRating = useSelector<RootState>(state => state.answers.currentRating);
  const checkedBoxes = useSelector<RootState>(state => state.answers.checkedBoxes);
  const [currentChecked, setCurrentChecked] = useState('');
  const [currentAnswer, setCurrentAnswer] = useState('');
  const [currentQuestion, setCurrentQuestion] = useState('');
 const selectedClass = rating === currentRating ? 'bg-slate-100 border-sky-400' : '';
const dispatch = useDispatch();

useEffect(() => {
  if(collectedData && collectedData.type !== InputType.TEXT && collectedData.type !== InputType.TEXTAREA ){ 
    setCurrentAnswer(collectedData.answer);
    setCurrentQuestion(collectedData.question);
  }
},[collectedData]);

  const onInput = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> ) => {
    dispatch(addData({
    title,
    answer: e.target.value,
    rating: rating,
    question,
    type: inputType
    }))
 }

function addThePath (){
    if(path === QuestionType.SELECTION && selectPath){
       dispatch(setQuestionPath([selectPath]));
    }
}

function removeFromPathSelection(){
  dispatch(removeQuestionPath(selectPath))
}

 const onCheck = (e: React.ChangeEvent<HTMLInputElement>): void | MouseEventHandler<HTMLButtonElement> => {
   const {value, checked} = e.target;
   // check if the typeOfQuestion is Start and then collect selectedPaths
   // then filter questions again using the selectedPaths
   setCurrentChecked(title + value)
   if((checked)){
    dispatch(addData({
    title,
    answer: value,
    rating: rating,
    type: inputType,
    question
    }))
    addThePath();
  } else{
    dispatch(removeData({
    title,
    answer: value,
    rating: rating,
    question,
    type: inputType,
    }))
    removeFromPathSelection();
  }
 }

  const onRadioButton = (e: React.ChangeEvent<HTMLInputElement>, rating: number) => {
    const {value, checked} = e.target;
    if((checked)){
     dispatch(radioBoxCheckedRating(rating));
     dispatch(addData({
    title,
    answer: value,
    rating: rating,
    type: inputType,
    question
    }))
  } else{
     dispatch(removeData(addData({
    title,
    answer: value,
    rating: rating,
    question,
    type: inputType,
    })))
  }
  setTimeout(() => {
    onNext();
  }, 400);
  }

  const onSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
   const {value, selectedIndex} = e.target;
   if((selectedIndex !== -1)){
    dispatch(addData({
    question: title,
    answer: value,
    rating: rating,
    type: inputType,
    }))
  }else {
    dispatch(removeData(addData({
    question: title,
    answer: value,
    rating: rating,
    type: inputType,
    })))
  }
 }

 const getValue = (title: string, question: string) => title === currentAnswer && question === currentQuestion ? title : '';

  if(inputType === InputType.TEXT ||
     inputType === InputType.EMAIL ||
     inputType === InputType.TEL
     ){
    return <div
    className='flex flex-col w-full
     justify-between p-2 min-h-10 border-2 
      rounded items-center
      hover:bg-slate-100
      duration-500'
    >
   <span className='font-bold'>{title}</span>
     <input
     className='border-2 rounded p-1 w-full'
     type={inputType.toLocaleLowerCase()}
     placeholder={placeHolder}
     onChange={(e)=>onInput(e)}
     defaultValue={getValue(title, question)}
     />
    </div>
  }

  if(inputType === InputType.NUMBER){
    return <div
    className='flex flex-col w-full
     justify-between p-2 min-h-10 border-2 
      rounded items-center
      hover:bg-slate-100
      duration-500'
    >
   <span className='font-bold'>{title}</span>
     <input
     className='w-full border-2 rounded p-1'
     type={inputType.toLocaleLowerCase()}
     min="1900"
     max="2010"
     step="1"
     placeholder={placeHolder}
     onChange={(e)=>onInput(e)}
     defaultValue={getValue(title, question)}
     />
    </div>
  }

  if(inputType === InputType.SELECT && selection){
    return <div
    className='flex flex-col w-full
     justify-between p-2 min-h-10 border-2 
      rounded items-center
      hover:bg-slate-100
      duration-500'
    >
   <span className='font-bold'>{title}</span>
     <select
     className='w-full border-2 rounded p-1'
     onChange={(e)=>onSelect(e)}
     defaultValue={getValue(title, question)}
     >
      {selection.map(value=> <option value={value}>{value}</option>)}
     </select>
    </div>
  }

  if(inputType === InputType.TEXTAREA){
    return <div
    className='flex flex-col w-full
     justify-between p-2 min-h-10 border-2 
      rounded items-center
      hover:bg-slate-100
      duration-500'
    >
   <span className='font-bold'>{title}</span>
     <textarea
     className='flex w-full border-2 rounded p-4 mt-4 min-h-80'
     rows={4}
     cols={100}
     maxLength={1000}
     placeholder={placeHolder}
     onChange={(e)=>onInput(e)}
     defaultValue={getValue(title, question)}
     />
    </div>
  }
  
  if(inputType === InputType.CHECKBOX){
    return <button
    className={`
      flex flex-row w-full
     justify-between p-2 min-h-10 border-2 
      rounded items-center
      hover:bg-slate-100
      duration-500 ${selectedClass}
    `}
    onClick={() => onCheck({target: { value:title, checked: true }} as any)}
    >
   <span className='font-bold'>{title}</span>
     <input
     checked={(checkedBoxes as string[]).includes(currentChecked) || !!getValue(title, question)}
     className='mx-2'
     type='checkbox'
     value={title}
     onChange={(e)=>onCheck(e)}
     />
    </button>
  }

  return (
    <button
     onClick={() => onRadioButton({target: { value:title, checked: true }} as any,rating)}
     className={`flex flex-row w-full
     justify-between p-2 min-h-10 border-2 
      rounded items-center
      hover:bg-slate-100
      duration-500 ${selectedClass}`}
      >
     <span className='font-bold'>{title}</span>
     <input
     onChange={(e)=>onRadioButton(e,rating)}
     value={title}
     checked={rating === currentRating || !!getValue(title, question)}
     type='radio'
     />
     </button>
  )
}
