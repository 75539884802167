// src/axiosConfig.ts

import axios from 'axios';

const BASE_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:3333' : 'https://icare-well-being-server.vercel.app'

console.log(process.env.NODE_ENV==='development')

const instance = axios.create({
  baseURL: BASE_URL,
  withCredentials: !(process.env.NODE_ENV === 'development'),
});

export default instance;
