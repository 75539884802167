import { createSlice } from "@reduxjs/toolkit";
import { MatchedTherapy, PickedAppointmentDay } from "../types/types";
import { QuestionFormPaths, QuestionType } from "../data/questions";

export enum BookingType {
    Other,
    Urgent,
    Normal,
}

export interface Payment {
  amount: number;
  currency: string;
  payment_method: string;
}

export interface BookingState {
  bookingType: BookingType;
  isPaymentSuccess: boolean;
  payment: Payment;
  match: MatchedTherapy;
  questionsPath: QuestionType;
  pickedAppointment: PickedAppointmentDay;
}

const initialState: BookingState = {
  bookingType: BookingType.Other,
  isPaymentSuccess: false,
  payment: {
    amount: 0,
    currency: "",
    payment_method: "",
  },
  match: {
    name: "",
    email: "",
    lcNumber: "",
    resCountry: "",
  },
  questionsPath: QuestionType.START,
  pickedAppointment: PickedAppointmentDay.OTHER,
};

export const chooseBookingType = createSlice({
  name: "booking-type",
  initialState,
  reducers: {
    chooseBooking: (state, action) => {
        switch (action.payload) {
          case 1:
            state.bookingType = BookingType.Urgent;
            break;
          case 2:
            state.bookingType = BookingType.Normal;
            break;
          default:
             state.bookingType = BookingType.Other;
        }
    },
    pay: (state, action) => {
       state.isPaymentSuccess = action.payload;
    },
    addPaymentDetails: (state, action) => {
     state.payment = action.payload;
    },
    setMatch: (state, action) => {
      state.match = action.payload;
    },
    setQuestionsPath:(state, action) => {
      state.questionsPath = action.payload;
    },
   setPickedAppointment: (state, action) => {
     state.pickedAppointment = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  chooseBooking,
  pay,
  addPaymentDetails,
  setMatch,
  setQuestionsPath,
  setPickedAppointment,
} = chooseBookingType.actions;

export default chooseBookingType.reducer;
