import { createSlice } from "@reduxjs/toolkit";
import { IUserInfo } from "../hooks/useSendPatient";
import { QuestionType } from "../data/questions";

interface collectedAnswers {
  question: string;
  answer: string;
  rating: number;
  type: InputType;
  title: string;
}

export enum InputType {
  TEXT = "Text",
  RADIO = "Radio",
  CHECKBOX = "Checkbox",
  SELECT = "SELECT",
  TEXTAREA = "TextArea",
  DATE = "Date",
  EMAIL = "email",
  TEL = "tel",
  NUMBER = 'number'
}

interface UserInfo {
  name: string;
  lastName: string;
  tel: string;
  email: string;
  gender: string;
  birthYear: number;
}

export interface QuestionState {
  data: collectedAnswers[];
  currentRating: number;
  checkedBoxes: string[];
  userInfo: UserInfo;
  selectedPaths: QuestionType[];
}

const initIPatientState: UserInfo = {
  name: "",
  lastName: "",
  tel: "",
  email: "",
  gender: "",
  birthYear: 0,
};

const initialState: QuestionState = {
  data: [],
  currentRating: 0,
  checkedBoxes: [],
  userInfo: initIPatientState,
  selectedPaths: [],
};

export const collectAnswersData = createSlice({
  name: "questions",
  initialState,
  reducers: {
    addData: (state, action) => {
      const { data } = state;
      switch (action.payload.type) {
        case InputType.TEXTAREA:
        case InputType.TEL:
        case InputType.EMAIL:
        case InputType.TEXT:
        case InputType.NUMBER:
          data.map((value, index) => {
            if (value.title === action.payload.title) {
              data.splice(index, 1);
            }
          });
          break;

        case InputType.RADIO:
          data.map((value, index) => {
            if (value.question === action.payload.question) {
              data.splice(index, 1);
            }
          });
          break;

        case InputType.CHECKBOX:
          prepareCheckboxes(state, action);
          break;
      }
      state.data = [...data, action.payload];

      // setup user info --------------------------------

      if (action.payload.question === IUserInfo.GENDER) {
        state.userInfo = {
          ...state.userInfo,
          gender: action.payload.answer,
        };
      }
      localStorage.removeItem("userInfo");
      switch (action.payload.title) {
        case IUserInfo.BIRTH_YEAR:
          state.userInfo = {
            ...state.userInfo,
            birthYear: action.payload.answer,
          };
          localStorage.setItem("userInfo", JSON.stringify(state.userInfo));
          break;
        case IUserInfo.EMAIL_ADDRESS:
          state.userInfo = {
            ...state.userInfo,
            email: action.payload.answer,
          };
          localStorage.setItem("userInfo", JSON.stringify(state.userInfo));
          break;
        case IUserInfo.NAME:
          state.userInfo = {
            ...state.userInfo,
            name: action.payload.answer,
          };
          localStorage.setItem("userInfo", JSON.stringify(state.userInfo));
          break;
        case IUserInfo.PHONE_NUMBER:
          state.userInfo = {
            ...state.userInfo,
            tel: action.payload.answer,
          };
          localStorage.setItem("userInfo", JSON.stringify(state.userInfo));
          break;
      }
    },
    removeData: (state, action) => {
      const checkValue = action.payload.title + action.payload.answer;
      const { data } = state;
      if (action.payload.type === InputType.CHECKBOX) {
        data.map((value, index) => {
          if (value.title + action.payload.answer === checkValue) {
            data.splice(index, 1);
          }
        });
      }
      data.splice(data.indexOf(checkValue), 1);
      console.log("data after removing check", state.data);
    },
    radioBoxCheckedRating: (state, action) => {
      state.currentRating = action.payload;
    },
    emptyChecks: (state) => {
      state.checkedBoxes = [];
    },
    setUserTel: (state, action) => {
      localStorage.setItem("userTel", action.payload);
      state.userInfo.tel = action.payload;
    },
    setQuestionPath: (state, action) => {
      console.log("action path add", action.payload);
      state.selectedPaths = [...state.selectedPaths, ...action.payload];
      console.log("path state", state.selectedPaths);
      // if (state.selectedPaths.indexOf(action.payload) !==-1){
        // state.selectedPaths = state.selectedPaths.splice(
        //   state.selectedPaths.indexOf(action.payload),
        //   1
        // );
      //    console.log("path state", state.selectedPaths);
      // } else {
      //    state.selectedPaths = [...state.selectedPaths, ...action.payload];
      //    console.log("path state", state.selectedPaths);
      // }
      
    },
    removeQuestionPath: (state, action) => {
      console.log("action path remove", action.payload);
      state.selectedPaths = state.selectedPaths.splice(
        state.selectedPaths.indexOf(action.payload),
        1
      );
      console.log("path state", state.selectedPaths);
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addData,
  removeData,
  radioBoxCheckedRating,
  emptyChecks,
  setUserTel,
  setQuestionPath,
  removeQuestionPath,
} = collectAnswersData.actions;

export default collectAnswersData.reducer;


type ActionWithPayload<T, P> = {
  type: T,
  payload: P,
};

const prepareCheckboxes = (
  state: QuestionState,
  action: ActionWithPayload<String, { title: string; answer: string; question : string}>
) => {
  console.log(
    "check checks",
    state.checkedBoxes.includes(action.payload.title + action.payload.answer),
    state.checkedBoxes.length
  );

  const value = action.payload.title + action.payload.answer;
  const { data } = state;

  if (state.checkedBoxes.includes(value)) {
    state.checkedBoxes.splice(state.checkedBoxes.indexOf(value), 1);
  } else {
    state.checkedBoxes = [...state.checkedBoxes, value];
  }
};