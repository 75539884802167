import React from 'react'
import RowDivider from '../component/stepper/rowDivider';
import { questionsData } from '../data/questions';

export default function NormalMatching() {
  return (
     <>
      <h1 className='text-4sm font-semibold lg:text-2xl md:text-2xl text-center w-full p-0'>
        </h1>
       <RowDivider data={questionsData} />
      </>
  )
}
