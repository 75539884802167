import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { chooseBooking } from '../redux/matchingType.reducer';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import RedirectingToStrip from '../component/payment/StripeRedirection';

export default function StartBooking() {
    const [selectedType, setSelectedType] = useState<number>(0);
    const [isPayment, setIsPayment] = useState<boolean>(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const normalBookingBorderColor = selectedType === 2 ? 'border-sky-500 shadow-2xl' : '';
    // const UrgentBookingBorderColor = selectedType === 1 ? 'border-sky-500 shadow-2xl' : '';

   const onSelect = (type: number) => {
        setSelectedType(0);
        setSelectedType(type);
        dispatch(chooseBooking(type))
    }

const onStartPayment = () => {
  onSelect(1);
  setIsPayment(true);
}

const onStartMatching = () => {
     onSelect(0);
     navigate('/normal');
}

if(isPayment) return <RedirectingToStrip />;

  return (
<div className='flex flex-col justify-center items-center '>
    <h1 className='text-4sm lg:text-4xl text-center font-serif'>Complete profile or skip</h1>
    <div className='flex flex-col flex-wrap-reverse w-full p-5 gap-2 items-center justify-center'>
      {/* <div className='flex flex-col w-2/4 p-1 min-w-80'>
       <div
       className={`p-5 border-2 flex flex-col
        justify-center items-center cursor-pointer
        hover:shadow-2xl shadow-md duration-500
        ${UrgentBookingBorderColor}`}
        onClick={() => onSelect(1)}
        >
         <img 
         src='assets/urgent-booking.png'
         alt=''
         height={300}
         width={300}
         />
         <h2 className='text-xl font-bold mt-4'>
            Urgent Booking
         </h2>
         <small className='text-center min-h-20'>fast and quick matching, share your phone number and we will take care of the rest</small>
       </div>
      </div> */}

      <button
        onClick={onStartPayment}
        className='button border-2 px-4 rounded-md
         w-full md:w-1/2 lg:w-1/2 p-1 bg-green-500
         text-white font-bold text-4sm 
         disabled:bg-blue-100
         hover:shadow-2xl hover:bg-green-600 hover:text-gray-100 shadow-md duration-500
         flex flex-row items-center
         '>
         <FontAwesomeIcon icon={faCheck} className="text-white ml-2 text-xl font-bold border-2 border-white rounded-2xl p-1" />
         <span className='text-center'>Confirm payment and complete booking</span>
        </button>

        <span className='p-2 font-bold font-serif'>OR</span>

      <div className='flex flex-col w-2/4 p-1 min-w-80'>
        <div
        className={`p-5 border-2 flex flex-col
        justify-center items-center cursor-pointer
        hover:shadow-2xl shadow-md duration-500
        ${normalBookingBorderColor}`}
         onClick={() => onSelect(2)}
         >
         <img
         src='assets/normal-booking.png'
         alt=''
         height={300}
         width={300}
         />
         <h2 className='text-xl text-center font-bold mt-4'>
            Complete Profile and get Matched automatically
         </h2>
     <small className='text-center min-h-20'>answer multiple questions and our smart system will let be in touch with the best therapy depending on your case</small>
     <button
        onClick={onStartMatching}
        className='button border-2 px-4 rounded w-60 p-1 bg-blue-500 text-white font-bold text-xl disabled:bg-blue-100'>
          Complete Profile
      </button>
       </div>
      </div>
    </div>
    </div>
  )
}
