import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from '../utils/axiosInstance';
import { useDispatch } from 'react-redux';
import { BookingType, addPaymentDetails } from '../redux/matchingType.reducer';
import Confirmation from '../component/payment/confirmation';

interface SessionData {
  paymentIntentId: string;
}

export default function PaymentSuccess() {
  const location = useLocation();
  const [paymentIntentId, setPaymentIntentId] = useState<string | null>(null);
  const dispatch = useDispatch();


  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const sessionId = query.get('session_id');
    const amount = localStorage.getItem('amount');
    const bookingType = localStorage.getItem('bookingType');
    const userInfo = localStorage.getItem('userInfo');
    const answersData = localStorage.getItem('answers')
    const tel = localStorage.getItem('userTel')

    if (sessionId) {
      // Fetch session details from your server
      axios.get<SessionData>(`/api/payment-success?session_id=${sessionId}`)
        .then(response => {
          setPaymentIntentId(response.data.paymentIntentId);

          dispatch(addPaymentDetails({
            amount: parseFloat(amount ?? '0'),
            payment_method: response.data.paymentIntentId,
            currency: 'AED',
          }))
          let body ={};
          if(amount && bookingType && response.data.paymentIntentId && tel){

            if(parseInt(bookingType) === BookingType.Normal && answersData && userInfo){

              const parsedUserInfo = JSON.parse(userInfo);
              const data = JSON.parse(answersData);

              body = {
                paymentIntent: response.data.paymentIntentId,
                amount: parseFloat(amount),
                bookingType: parseInt(bookingType),
                ...parsedUserInfo,
                tel,
                data,
             }

            } else {
              body = {
                paymentIntent: response.data.paymentIntentId,
                amount: parseFloat(amount),
                bookingType: parseInt(bookingType),
                tel,
             }
            }
              axios.patch('/api/order',body).then(res=>{
                    console.log('order status res',res.data);
                    if(res.status === 200){
                        localStorage.removeItem('answers');
                        localStorage.removeItem('userInfo');
                    }
                 })
          }
        })
        .catch(error => {
          console.error('Error fetching session details:', error);
        });
    }
  }, []);

  const returnMatching = () => {
    return <Confirmation/>
  }

  return (
    <div>
      {paymentIntentId ? (
        <div className='flex flex-col space-y-4 justify-center align-center w-full h-full'>
          {returnMatching()}
        </div>
      ) : (
        <div className='w-full flex items-center justify-center'>
        <div className="loader w-[20px] h-[20px]"></div>
        </div>
      )}
    </div>
  );
}
