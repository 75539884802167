import React, { useState } from 'react';
import { useSendFeedBackMutation } from '../hooks/useFeedBack';

const Feedback: React.FC = () => {
  const [rating, setRating] = useState(0);
  const [hoverRating, setHoverRating] = useState(0);
  const [comment, setComment] = useState('');
  const [sendFeedBack, {isLoading, isError, isSuccess}] =useSendFeedBackMutation()

  const handleRating = (rate: number) => {
    setRating(rate);
  };

  const handleHoverRating = (rate: number) => {
    setHoverRating(rate);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const feedbackData = {
      rating,
      comment,
    };

    try {
        await sendFeedBack(feedbackData);
        console.log(isError, isSuccess)
    } catch (error) {
      console.error('Error submitting feedback', error);
    }
  };

  return (
    <div className="max-w-lg mx-auto p-6 bg-white border border-gray-200 rounded-lg shadow-md w-full">
        {
         isError &&  <p className='text-red-500'>cannot submit the feedback at the moment please try later!</p>
        }
        {
         isSuccess &&  <p className='text-green-500'>Submitted Successfully, Thank you.</p>
        }
      <form onSubmit={handleSubmit}>
        <div className="flex justify-center mb-4">
          {[1, 2, 3, 4, 5].map((star) => (
            <i
              key={star}
              className={`fas fa-star text-2xl cursor-pointer mx-1 ${star <= (hoverRating || rating) ? 'text-yellow-400' : 'text-gray-300'}`}
              onClick={() => handleRating(star)}
              onMouseEnter={() => handleHoverRating(star)}
              onMouseLeave={() => setHoverRating(0)}
            ></i>
          ))}
        </div>
        <div className="mb-4">
          <textarea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Leave your feedback here..."
            required
            className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <button
          type="submit"
          className="w-full p-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
            {!isLoading ? 'Submit' : 'Please wait'}
        </button>
      </form>
    </div>
  );
};

export default Feedback;
