import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { collectAnswersData } from "./questions.reducer";
import { chooseBookingType } from "./matchingType.reducer";
import { PatientReducer } from "../hooks/useSendPatient";
import { setupListeners } from "@reduxjs/toolkit/query";
import { FeedBackReducer } from "../hooks/useFeedBack";
import { bookingPlan } from "./paymentPlan.reducer";

export const store = configureStore({
  reducer: combineReducers({
    answers: collectAnswersData.reducer,
    bookingType: chooseBookingType.reducer,
    bookingPlan: bookingPlan.reducer,
    [PatientReducer.reducerPath]: PatientReducer.reducer,
    [FeedBackReducer.reducerPath]: FeedBackReducer.reducer,
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(PatientReducer.middleware, FeedBackReducer.middleware),
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
