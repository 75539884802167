export interface MatchedTherapy {
  email: string;
  name: string;
  lcNumber: string;
  resCountry: string;
}

export enum PickedAppointmentDay {
  SAME_DAY = "Same day",
  NEXT_DAYS = "Next days",
  FIVE_MINUTES = "Five minutes",
  OTHER = "Other",
}

export enum PickedAppointmentSameDay {
  FIVE_MINUTES = 'Five minutes',
  NEXT_THREE_HOURS = 'Next three hours',
}

export enum PickedAppointmentNextDays {
  FAST_FORM = 'Fast form',
  LONG_FORM = 'Long form'
}