// src/components/CheckoutButton.tsx

import React, { useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import axios from '../../utils/axiosInstance';
import { useDispatch, useSelector } from 'react-redux';
import { setSessionId } from '../../redux/paymentPlan.reducer';
import { RootState } from '../../redux/store';
import { PaymentsPlans } from '../../views/SelectPlan';
import { useNavigate } from 'react-router-dom';

const stripePromise = loadStripe('pk_test_51K2zoDFF6Kdh1bHtlOWheskRLULzknJR6KTMVKosMMoJlqO9aqJZvmcznfDnCL0GbZsdWst26oyDvKYF2zXKt8Hv00ZUiUS6EA');


export const selectedAmount = [
  0,
  90,
  324,
  590,
]



const RedirectingToStrip: React.FC = () => {
  const selectedPlan: number = useSelector<RootState>(state => state.bookingPlan.selectedPlan) as number;
  const bookingType: any = useSelector<RootState>(state => state.bookingType.bookingType);
  const navigate = useNavigate();

  const dispatch = useDispatch();

    useEffect(() => {
     handleCheckout();
    },[])

  const handleCheckout = async () => {
    const stripe = await stripePromise;

    try {
      localStorage.setItem('amount', selectedAmount[selectedPlan].toString());
      localStorage.setItem('bookingType', bookingType.toString());
      // Create a checkout session on the server
      const response = await axios.post('/api/create-checkout-session',{
        amount: selectedAmount[selectedPlan],
        plan: PaymentsPlans[selectedPlan].title
      });
      const session = response.data;

       dispatch(setSessionId(session.id));

      // Redirect to Stripe Checkout
      const result = await stripe?.redirectToCheckout({
        sessionId: session.id,
      });

      if (result?.error) {
        // Handle error
        console.error(result.error.message);
      }
    } catch (error) {
     navigate('/');
      console.error('Error creating checkout session:', error);
    }
  };

  return (
    <>
    <p className='text-2xl font-bold text-center'>Please wait! ,Redirecting to stripe</p>
    <div className='w-full flex items-center justify-center'>
      <div className="loader w-[20px] h-[20px]"></div>
    </div>
    </>
  );
};

export default RedirectingToStrip;
