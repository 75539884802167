import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { format } from 'date-fns';
import Modal from 'react-modal';
import axios from '../../utils/axiosInstance';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setPickedAppointment } from '../../redux/matchingType.reducer';
import { PickedAppointmentDay } from '../../types/types';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  },
};

type Props = {
    orderId?: string,
    tel: string;
    disabled? : boolean;
}

export default function Appointments({tel, disabled}: Props) {
  const [date, setDate] = useState<Date[]>([]);
  const [time, setTime] = useState<string[]>([]);
   const [modalIsOpen, setIsOpen] = useState(false);
   const [isNumberExist, setIsNumberExist] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [selectTime, setSelectTime] = useState<string[]>([]);
   const [isFiveMinute, setIsFiveMinute] = useState<boolean>(false);
   const [isFiveMinuteChecked, setIsFiveMinuteChecked] = useState<boolean>(false)
   const [session, setSessions] = useState<number>(0);
   const [selectedDays, setSelectedDays] = useState<string[]>([]);
   const dispatch = useDispatch();
   const navigate = useNavigate();


   useEffect(() =>{
    let hours: string[] = [];
    const currentTime = new Date().getHours();
      times.map((time: string) => {
        if(parseInt(time.substring(0,2)) > currentTime + 3 ) {
           hours.push(time);
        }
      })
      // setIsFiveMinute(true);
      setSelectTime(hours);
   },[])

   useEffect(() => {
     setDate([]);
   },[session])

   useEffect(() => {
     dispatch(setPickedAppointment(PickedAppointmentDay.SAME_DAY))
   },[dispatch, isFiveMinuteChecked])

   const onOrderUpdate = (startDateTime: string[]) => {
     setIsLoading(true);
     const body = {
      isFiveMinute: isFiveMinuteChecked,
      startDateTime,
      tel,
     }
     console.log(startDateTime)
       axios.post('/api/appointment',body).then((response: any)=>{
          if(response.data.ok){
            navigate('/plans')
           return closeModal();
          }
       })
       .catch((error)=>{
        alert('Error cannot take an appointment' + error)
       })
       .finally(()=>{
        setIsLoading(false);
       })
   }

 async function openModal() {
  setIsLoading(true);
    const response = await axios.post('/api/checkNumber',{
      tel
    });
    setIsNumberExist(!response.data.ok);
    if(response.data.ok){
      setIsOpen(true);
    }
    setIsLoading(false);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const times = [
    '09:00', '09:30', '10:00', '10:30',
    '11:00', '11:30', '12:00', '12:30',
    '13:00', '13:30', '14:00', '14:30',
    '15:00', '15:30', '16:00', '16:30',
    '17:00', '17:30', '18:00', '18:30',
    '19:00', '19:30', '20:00', '20:30',
    '21:00', '21:30', '22:00', '22:30',
  ];

  const availability = [
    'Morning (8:00 AM - 12:00 PM Dubai Time)',
    'Afternoon (12:00 PM - 4:00 PM Dubai Time)',
    'Evening (4:00 PM - 9:00 PM Dubai Time)',
  ]

  const sessions = [
    {title:'Single session', num: 1},
    {title:'X4 Sessions', num: 4},
    {title:'X8 Sessions', num: 8}
  ]

  const handleDateChange = (value: any) => {
     setIsFiveMinute(false);
     setSelectTime([])
    if(isFiveMinuteChecked) return;
    if(selectTime.length === 0 && new Date(value).setHours(0,0,0,0) === new Date().setHours(0,0,0,0)) return;
    if(date.includes(value)){
       let index = date.indexOf(value);
      let newDate = date.splice(index,1);
      return setDate(newDate)
    }
    if(session === 1 && date.length === 0){
      setDate([value]);
    }
    if(session === 4 && date.length < 4){
      setDate([...date,value]);
    }
    if(session === 8 && date.length < 8){
      setDate([...date,value]);
    }
    getPickedDay(value);
  };

  const handleTimeChange = (value: string) => {
    if(isFiveMinuteChecked) return;
    if(time.includes(value)){
      time.splice(time.indexOf(value),1);
      setTime([...time]);
      return;
    }
    setTime([...time, value]);
  };

 const tileDisabled = ({ date, view }: any, dates: Date[]) => {
  if(dates.length === session){
    if(selectTime.length === 0){
      return view === 'month' && date <= new Date().setHours(0, 0, 0, 0)
    }
     return view === 'month' && !dates.includes(date);
  }
   return view === 'month' && date < new Date().setHours(0, 0, 0, 0)
  };

  const handleSubmit = () => {
    let startDates: any[] = [];
   time.map(value => {
      date.map(d=>{
      const dateTime = new Date(`${format(d, 'yyyy-MM-dd')}T${value}:00`);
     if(!startDates.includes(dateTime) || !startDates.includes(value)){
       if(times.includes(value)){
       startDates.push(dateTime.toString())
     } else{
         startDates.push(value+'_'+d)
      }
     }
      })
    })
     onOrderUpdate(startDates)
  };

  function onCalendarDaysClick(value: Date, _event: any) {
    if(date.length < session){
     if(date.indexOf(value) !==-1){
      const newDate = date.filter(d=> d.toString() !== value.toString());
      if(newDate.length > session) return;
      if(selectTime.length === 0 && new Date(value).setHours(0,0,0,0) === new Date().setHours(0,0,0,0)) return;
      setDate(newDate);
     }
    }
  }

  const getPickedDay = (date: any) => {
    let hours: string[] = [];
    const pickedDay = new Date(date).getDate();
    const today = new Date().getDate();
    const currentTime = new Date().getHours();

    if(pickedDay === today) {
      dispatch(setPickedAppointment(PickedAppointmentDay.SAME_DAY))
       times.map((time: string) => {
         if(parseInt(time.substring(0,2)) > currentTime + 1.5 ) {
           hours.push(time);
        }
      })
      setIsFiveMinute(true);
      setSelectTime(hours);
    }
    else {
       dispatch(setPickedAppointment(PickedAppointmentDay.NEXT_DAYS))
       setIsFiveMinute(true);
       setSelectTime(availability)
    }
  }

  const onSelectedDateClick = (value: Date) => {
    if(isFiveMinuteChecked) return;
    const newDate = date.filter(d => d !== value)
    setDate(newDate);
  }

  if(isLoading){
    return(
         <div className='w-full flex flex-col items-center justify-center'>
         <div className="flex space-x-1">
         <div className="w-2 h-2 bg-blue-600 rounded-full animate-bounce"></div>
         <div className="w-2 h-2 bg-blue-600 rounded-full animate-bounce delay-75"></div>
         <div className="w-2 h-2 bg-blue-600 rounded-full animate-bounce delay-50"></div>
         <div className="w-2 h-2 bg-blue-600 rounded-full animate-bounce delay-25"></div>
         <div className="w-2 h-2 bg-blue-600 rounded-full animate-bounce delay-20"></div>
         <div className="w-2 h-2 bg-blue-600 rounded-full animate-bounce delay-150"></div>
         </div>
        </div>
    )
  }


  return (
    <>
      <button
      disabled={disabled}
      onClick={openModal}
      className='bg-blue-500 text-white rounded border-white p-4 text-xl font-bold hover:bg-blue-700 delay-100 disabled:bg-gray-400'>
          Book an appointment
     </button>
    <Modal
        isOpen={isNumberExist}
        onRequestClose={()=>setIsNumberExist(false)}
        contentLabel="Number check Error"
        style={customStyles}
      >
       <div className='flex flex-col max-w-[300px] space-y-4 justify-center items-center'>
        <h4 className='text-red-400 font-bold text-4xl'>Error</h4>
        <span className='text-2sm font-bold font-serif'>Number Already Exist, please use an other number</span>
       </div>
     </Modal>
    <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Appointment"
        style={customStyles}
      >

    <div className="appointments max-h-[80vh] py-1">
      <h1 className="text-2xl font-bold mb-4">Book an Appointment</h1>
      <div className='mb-1'>
        <label className='font-bold'>Select number of sessions:</label>
        <div className='card w-full p-2 flex flex-row flex-wrap gap-1 justify-center'>
          {sessions.map(({title,num})=>(
            <span
            onClick={()=> setSessions(num)}
            className={`border-[1px] rounded-md p-2 font-sans-serif font-light
              text-md ${session === num ? 'bg-blue-500 text-white' : ''}
               cursor-pointer hover:bg-gray-200 delay-200`}
            key={num}>{title}</span>
          ))}
        </div>
      </div>
      <div className="calendar-container mb-4 w-full flex justify-center items-center">
        {session!==0 &&
         <Calendar 
          onClickDay={onCalendarDaysClick}
          tileDisabled={(e:any)=>tileDisabled(e, date) || isFiveMinuteChecked} 
          onChange={handleDateChange}
          />}
      </div>

      <div className='flex flex-col w-full flex-wrap items-center  justify-center time-selector mb-4 gap-1'>
        <label>Selected Days:</label>
        <div className='flex flex-row max-w-[300px] flex-wrap
         space-x-2 p-1 justify-center items-center'>
          {
            date.map(d=>(
            <span
              onClick={()=>onSelectedDateClick(d)}
              className={`p-1 border-2 rounded w-1/8
               ${isFiveMinuteChecked ? 'bg-gray-400 text-black cursor-not-allowed hover:bg-gray-400'
              : 'cursor-pointer hover:bg-red-300 hover:text-white bg-green-300' }
              `}
              key={d.toString()}>{`${d.getDate()}-${d.getMonth()}`}</span>
            ))
          }
          {date.length===0 && <p className='text-gray-400'>No days selected</p>}
        </div>
      </div>

      {selectTime.length>0 && 
      (<div className="flex flex-col w-full flex-wrap items-center  justify-center time-selector mb-4 gap-1">
        <label htmlFor="time" className="block text-md font-medium">Select hours:</label>
        <div className='flex flex-row max-w-[300px] flex-wrap justify-center items-center gap-1 w-full px-2'>
          {date.length>0 && selectTime.map((value) => (
            <span
            onClick={()=>handleTimeChange(value)}
            className={`px-1 rounded border-[0.5px] text-light cursor-pointer
            hover:bg-gray-200 disabled:bg-gray-400
            text-md ${time.includes(value) ? 'bg-blue-500 text-white' : '' }
            ${isFiveMinuteChecked ? 'bg-gray-400 text-black cursor-not-allowed hover:bg-gray-400' : '' }
            `}
            key={value}>
            {value}
            </span>
          ))}
          {date.length===0 && <p className='text-gray-400'>Select a date first</p>}
        </div>
      </div>)}
     
     {isFiveMinute && (
      <div className='p-4 flex justify-center items-center space-x-4'>
        <input 
        className='p-2 appearance-none checked:bg-green-600 border-2 border-blue-400 rounded cursor-pointer'
         type='checkbox' 
         onChange={(e)=>setIsFiveMinuteChecked(e.target.checked)}
         />
        <label className='text-1xl font-bold font-serif'>Get in touch in just 5 min</label>
      </div>
     )}

      <button
        onClick={handleSubmit}
        className="bg-blue-500 text-white font-bold py-2 px-4 rounded w-full mb-4"
      >
        Confirm Appointment
      </button>
    </div>

      </Modal>
    </>
  )
}
