import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../utils/const";

interface FeedBack {
    comment: string;
    rating: number;
}

export const FeedBackReducer = createApi({
  reducerPath: "feedback",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    headers: {
      "Content-Type": "application/json",
    },
    // credentials: "include",
  }),
  endpoints: (builder) => ({
    sendFeedBack: builder.mutation<any, FeedBack>({
      query: (feedback) => ({
        url: `feedback`,
        method: "POST",
        body: feedback,
      }),
    }),
  }),
});

export const { useSendFeedBackMutation } = FeedBackReducer;
