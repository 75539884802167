import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { Link } from 'react-router-dom';

export default function Confirmation() {
    const payment: any = useSelector<RootState>(state => state.bookingType.payment);
  return (
    <div className='flex flex-col justify-center items-center gap-8 w-100'>
      <h1 className='text-2xl font-serif'>Successfully submitted</h1>
      <p className='font-serif text-sm'>Thank you for submitting you number to us we will contact you in certain minutes</p>
      <div className='flex flex-col justify-center items-center border-2 w-[80%] p-5'>
       <img
       alt=''
       src='assets/confirmation.png'
       width={80}
       />
       <h2 className='text-2xl font-serif'>We will be in touch shortly</h2>
       <div className='flex flex-col w-full p-2'>
        <table className='table w-full text-center mt-4'>
            <tr>
                <td>Order Id</td>
                <td># {payment.payment_method}</td>
            </tr>
            <tr>
                <td>Amount</td>
                <td>{payment.amount} {payment.currency}</td>
            </tr>
            <tr>
                <td>status</td>
                <td>in progress</td>
            </tr>
        </table>
        <br/>
        {/* <Appointments orderId={payment.payment_method}/> */}
        <Link
        to={'/feedback'}
        className='boxed border-2 p-2 mt-10 text-orange-800 font-bold rounded hover:bg-gray-100 duration-500 hover:border-sky-400'>
            Please click here to leave a feedBack
        </Link>
       </div>
      </div>
    </div>
  )
}
