import React from 'react'

export default function PaymentIcons() {
  return (
    <div className="flex w-full item-center justify-center gap-2">
      <i className="fab fa-cc-visa text-sky-600 text-4xl"></i>
      <i className="fab fa-cc-mastercard text-sky-600 text-4xl"></i>
      <i className="fab fa-stripe text-sky-600 text-4xl"></i>
    </div>
  )
}
