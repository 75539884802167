import React from 'react'
import PaymentIcons from '../payment/paymentIcons';

type Props = {
    children: React.ReactElement;
}

export default function Container({children}: Props) {
  const date = new Date();
  return (
    <div className='flex flex-col justify-center items-center w-full h-full'>
        <img
        alt=''
        src='assets/booking-logo.webp'
        height={100}
        width={100}
        className='p-4 h-20'
        />
        <div className='flex flex-col justify-center items-center p-5 w-full'>
          {children}
        </div>
        <div className='h-10'>
         <small>All right reserved <a className='text-sky-700' href='https://icarewellbeing.com/about-our-stories/'>ICareWellBeing</a> - Dubai {date.getFullYear()}</small>
         <PaymentIcons/>
        </div>
    </div>
  )
}
