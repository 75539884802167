import React from 'react';
import './App.css';
import Container from './component/ui/container';
import { BrowserRouter } from 'react-router-dom';
import ViewsRoutes from './views';

function App() {
  return (
    <Container>   
      <BrowserRouter>
         <ViewsRoutes/>
      </BrowserRouter>
    </Container>
  );
}

export default App;
